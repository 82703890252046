<template>
    <div id="dialog-contact-component">
        <v-dialog v-model="dialog_contact" max-width="500px">
            <v-card>
                <v-container>
                    <v-card-title>
                        <p class="text-uppercase font-weight-bold">Besoin d'aide</p>
                    </v-card-title>

                    <v-card-subtitle>
                        <p class="mb-2 font-14 line-height-16">Vous rencontrez un problème ?</p>
                        <p class="font-14 line-height-16">
                            Contactez-nous. Nos équipes répondront à votre problèmatique dans les meilleurs délais
                        </p>
                    </v-card-subtitle>

                    <v-card-text class="pb-0">
                        <div class="mb-6">
                            <v-select
                                v-model="problem_encountered_id"
                                :items="contacts"
                                :menu-props="{ bottom: true, offsetY: true }"
                                class="mb-2"
                                filled
                                hide-details
                                item-text="problem_concerned"
                                item-value="id"
                                label="Votre problème concerne"
                            />
                            <template v-if="problem_encountered_id">
                                <p class="mb-1">Email de contacts :</p>
                                <p
                                    v-for="contact in contacts.find((item) => item.id === problem_encountered_id).emails"
                                    v-bind:key="contact"
                                    class="mb-0 pl-4 font-color-medium"
                                >
                                    {{ contact }}
                                </p>
                            </template>
                        </div>

                        <v-textarea v-model="message" filled label="Message" />

                        <div class="dropzone" @dragenter="dragging = true" @dragleave="dragging = false">
                            <div class="dropzone-info" @drag="onChange">
                                <div class="dropzone-upload-limit-info pl-3 pt-4 pb-4">
                                    <span :class="dragging ? 'primary--text' : ' font-color-medium'">
                                        {{ $t('send_bug_page.import_image') }}
                                    </span>
                                    <p class="font-color-light font-14 mb-n1 line-height-16">
                                        {{ $t('send_bug_page.drop_or_click_image') }}
                                    </p>
                                    <p class="font-color-light font-10 mb-0">
                                        {{ $t('global.format_pgn_jpeg') }}
                                    </p>
                                    <input multiple type="file" @change="onChange" />
                                </div>
                            </div>

                            <div v-for="(file, index) in files" :key="index" class="mb-1 ml-2 font-14">
                                <a :href="urls[index]" target="_blank">{{ file.name }}</a>
                                <v-icon small @click="files.splice(index, 1)">mdi-close</v-icon>
                            </div>
                        </div>

                        <p class="font-weight-light font-color-medium font-12 line-height-14">
                            Certaines informations concernant le compte et le système pourront être envoyées à Oleo100. Nous les utiliserons
                            pour résoudre des problèmes et améliorer nos services, conformément à nos <a>Règles de confidentialité</a> et à
                            nos <a>Conditions d'utilisation</a>. Nous pourrons vous demander plus d'informations ou vous en renvoyer par
                            e-mail.
                        </p>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog_contact = false">
                            <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>

                        <v-btn :disabled="!valid" color="secondary" text @click="sendAssistanceRequired()">
                            <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import GlobalRepository from '@/repositories/GlobalRepository'

export default {
    name: 'DialogContactComponent',
    data() {
        return {
            dialog_contact: false,
            message: '',
            problem_encountered_id: null,
            contacts: [
                {
                    id: 1,
                    problem_concerned: 'Cuve',
                    emails: ['servicecuve.oleo100@avril.com'],
                },
                {
                    id: 2,
                    problem_concerned: 'Commande et facturation',
                    emails: ['serviceclient.oleo100@groupeavril.com', 'carla.matias@groupeavril.com', 'ingrid.herard@groupeavril.com'],
                },
                {
                    id: 3,
                    problem_concerned: 'Produit',
                    emails: ['qualite.saipol@groupeavril.com'],
                },
                {
                    id: 4,
                    problem_concerned: 'Véhicule',
                    emails: ['servicevehicule.oleo100@groupeavril.com'],
                },
                {
                    id: 5,
                    problem_concerned: 'Communication',
                    emails: ['communication@oleo100.com'],
                },
            ],
            files: [],
            dragging: false,
            valid_format_picture: 'image/png, image/jpeg',
            urls: [],
        }
    },
    methods: {
        sendAssistanceRequired() {
            let formData = new FormData()

            this.files.forEach((file) => {
                formData.append('files[]', file)
            })

            formData.append('id_problem', this.problem_encountered_id)
            formData.append('message', this.message)

            GlobalRepository.sendAssistanceRequired(formData)
                .then(() => {
                    this.showSnackbar('success', "Demande d'assistance envoyée.")
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.dialog_contact = false
                    this.problem_encountered_id = null
                    this.message = ''
                })
        },

        onChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                this.dragging = false
                return
            }

            if (this.checkFiles(files[0])) {
                this.createFile(files[0])
            }
        },

        checkFiles(new_file) {
            let valid = true

            if (new_file.type === "") {
                this.showSnackbar('error', this.$t('send_bug_page.file_type_not_authorized'))
                valid = false
            }

            if (!this.valid_format_picture.includes(new_file.type)) {
                this.showSnackbar('error', this.$t('send_bug_page.file_type_not_authorized'))
                valid = false
            }

            this.files.forEach((file) => {
                if (file.name === new_file.name) {
                    this.showSnackbar('error', this.$t('send_bug_page.file_already_selected'))
                    valid = false
                }
            })

            return valid
        },

        createFile(file) {
            this.files.push(file)
            this.urls.push(URL.createObjectURL(file))
            this.dragging = false
        },
    },
    computed: {
        valid() {
            return this.problem_encountered_id && this.message
        },
    },
}
</script>

<style>
    .dropzone {
        width: 100%;
        position: relative;
        border: 1px dashed #7f828a;
        background: var(--v-white-base);
        border-radius: 5px 5px 0 0 !important;
        margin-bottom: 20px;
    }

    .dropzone:hover {
        border: 1px dashed var(--v-primary-base);
    }

    .dropzone-upload-limit-info {
        position: relative;
        height: fit-content;
    }

    .dropzone input {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
</style>
