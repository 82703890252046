import axios from '@/plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IAssetCompleteInformation } from '@/models/IAssetCompleteInformation'
import type { IServerResponse } from '@/models/IServerResponse'
import { IModelReference } from '@/models/IModelReference'

const resource = 'api/v1/asset'

export default {
    getAssetById(id_asset: number): Promise<AxiosResponse<IServerResponse<IAssetCompleteInformation>>> {
        return axios.get(`${resource}/v2/${id_asset}`)
    },

    getAssetByAssetTypeAndClient(
        idClient: number,
        idAssetType: number
    ): Promise<AxiosResponse<IServerResponse<IAssetCompleteInformation[]>>> {
        return axios.get(`${resource}/asset_type/${idAssetType}`)
    },

    getCo2EconomyInYear(idAsset: number): Promise<AxiosResponse<IServerResponse<[]>>> {
        return axios.get(`${resource}/${idAsset}/year_co2_economy`)
    },

    getAssetTags(idAsset: number): Promise<AxiosResponse<IServerResponse<[]>>> {
        return axios.get(`${resource}/${idAsset}/tags`)
    },

    postUploadPicture(idAsset: number, files): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.post(`${resource}/${idAsset}/image`, files)
    },

    putEditAsset(idAsset: number, asset, domicile): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(resource + '/' + idAsset, { asset: asset, domicile: domicile })
    },

    updateAssetV2(id_asset: number, updated_asset): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/v2/${id_asset}`, updated_asset)
    },

    putEditAssetName(idAsset: number, assetName: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(resource + '/' + idAsset + '/name', { asset_name: assetName })
    },

    deletePicture(idAsset: number, name): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.delete(`${resource}/${idAsset}/image?name=${name}`)
    },

    getAssetImages(id_asset: number): Promise<AxiosResponse<IServerResponse<Array<string>>>> {
        return axios.get(`${resource}/${id_asset}/images`)
    },

    getAssetReference(id_asset: number): Promise<AxiosResponse<IServerResponse<IModelReference>>> {
        return axios.get(`${resource}/${id_asset}/asset_reference`)
    },

    getAssetsByIdModuleAndIdClient(id_module: number): Promise<
        AxiosResponse<
            IServerResponse<{
                data: Array<IAssetCompleteInformation>
            }>
        >
    > {
        return axios.get(`${resource}/client/module/${id_module}?limit=20`)
    },

    getAssetsCountByIdModuleAndIdClient(id_module: number): Promise<AxiosResponse<IServerResponse<{ count: number }>>> {
        return axios.get(`${resource}/client/module/${id_module}/count`)
    },

    getAssetsByIdModuleAndIdClientCsv(id_module: number): Promise<AxiosResponse<IServerResponse<Blob>>> {
        return axios.get(`${resource}/client/module/${id_module}/csv`, { responseType: 'blob' })
    },
}
